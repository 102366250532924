'use client';

import Image from 'next/image';
import { PropsWithChildren, useEffect, useState } from 'react';
import Script from 'next/script';
import process from 'process';
import Logo from '../../../public/logo.svg';

type State = {
    verifying: boolean;
    status: Status;
};

type RecaptchaVersion = 'v2' | 'v3';

export enum Status {
    INITIAL,
    ADDITIONAL_VERIFICATION,
    BOT_DETERMINED,
    HUMAN_DETERMINED,
}

const DEFAULT_STATE: State = {
  verifying: true,
  status: Status.INITIAL,
};

const POLLING_RATE_MS = 1000;

const RECAPTCHA_SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;
const RECAPTCHAV2_SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHAV2_SITE_KEY;

interface Props {
    source: string;
    destination: string;
    productId: string;
}

function ReCaptchaInterstitialBox({ children }: PropsWithChildren<any>) {
  return (
    <div className='flex justify-center items-center h-screen mx-5 -mt-20 sm:mt-0'>
      <div className='bg-white overflow-hidden md:rounded w-full h-full max-h-[400px] md:w-[600px] shadow-xl shadow-indigo-900/30 border-t border-gray-100'>
        <div className='flex flex-col justify-between'>
          <div className='flex flex-col items-center justify-end gap-y-12 px-8 pt-12 pb-5'>
            <Image
              priority
              src={Logo}
              alt='Comparison411 Logo'
              className='h-16'
            />
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

function LoadingAnimation() {
  return (
    <div className='flex items-center justify-center space-x-2'>
      <div
        className='w-4 h-4 animate-bounce rounded-full bg-blue-500'
        style={{ animationDelay: '0.1s' }}
      />
      <div
        className='w-4 h-4 animate-bounce ease-in-out rounded-full bg-blue-400'
        style={{ animationDelay: '0.2s' }}
      />
      <div
        className='w-4 h-4 animate-bounce delay-150 ease-in-out rounded-full bg-blue-200'
        style={{ animationDelay: '0.3s' }}
      />
    </div>
  );
}

function VerificationPending() {
  return (
    <>
      <h2 className='text-xl text-black'>Verifying you are human</h2>
      <LoadingAnimation />
    </>
  );
}

function BotDetectionPane() {
  return (
    <>
      <h2 className='text-xl text-black'>
        Unable to handle your request. Please try again later.
      </h2>
      <h1 className='text-5xl'>❌</h1>
    </>
  );
}

async function exchangeToken(
  token: string,
  source: string,
  destination: string,
  productId: string,
  version: RecaptchaVersion,
) {
  const data = {
    token, destination, version, source, productId,
  };

  const response = await fetch('/api/verify', {
    method: 'POST',
    body: JSON.stringify(data),
    credentials: 'include',
    cache: 'no-store',
  });

  return response.json();
}

function ReCaptchaCheckbox({ destination, source, productId }: Props) {
  // @ts-ignore
  window.v2_callback = (token: string) => {
    exchangeToken(token, source, destination, productId, 'v2').then((result) => {
      if (result.disposition === 'human') {
        window.location.replace(result.destination);
      }
    });
  };
  // noinspection JSUnresolvedLibraryURL
  return (
    <>
      <form action='?' method='POST'>
        <div
          className='g-recaptcha'
          data-sitekey={RECAPTCHAV2_SITE_KEY}
          data-callback='v2_callback'
        />
        <br />
        <input type='submit' value='Submit' style={{ visibility: 'hidden' }} />
      </form>
      <Script
        id='load-recaptchav2'
        src='https://www.google.com/recaptcha/api.js'
      />
    </>
  );
}

function AdditionalVerification({ destination, source, productId }: Props) {
  return (
    <>
      <h2 className='text-xl text-black'>Please verify that you are human.</h2>
      <ReCaptchaCheckbox destination={destination} source={source} productId={productId} />
    </>
  );
}

export default function ReCaptchaSection({ destination, source, productId }: Props) {
  const [state, setState] = useState(DEFAULT_STATE);
  useEffect(() => {
    const interval = setInterval(async () => {
      // @ts-ignore
      if (state.verifying && typeof grecaptcha !== 'undefined') {
        // @ts-ignore
        grecaptcha.ready(() => {
          if (state.status === Status.INITIAL) {
            // @ts-ignore
            // noinspection JSUnresolvedReference
            grecaptcha
              .execute(RECAPTCHA_SITE_KEY, { action: 'interstitial' })
              .then((token: string) => {
                exchangeToken(token, source, destination, productId, 'v3').then((rs) => {
                  switch (rs.disposition) {
                  case 'human':
                    setState({
                      verifying: false,
                      status: Status.HUMAN_DETERMINED,
                    });
                    window.location.replace(rs.destination);
                    break;
                  case 'bot':
                    setState({
                      verifying: false,
                      status: Status.BOT_DETERMINED,
                    });
                    break;
                  default:
                    setState({
                      verifying: true,
                      status: Status.ADDITIONAL_VERIFICATION,
                    });
                  }
                });
                clearInterval(interval);
              });
          }
        });
      }
    }, POLLING_RATE_MS);

    return () => clearInterval(interval);
  });

  return (
    <ReCaptchaInterstitialBox>
      {
        {
          [Status.INITIAL]: <VerificationPending />,
          [Status.ADDITIONAL_VERIFICATION]: (
            <AdditionalVerification destination={destination} source={source} productId={productId} />
          ),
          [Status.BOT_DETERMINED]: <BotDetectionPane />,
          [Status.HUMAN_DETERMINED]: <VerificationPending />,
        }[state.status]
      }
    </ReCaptchaInterstitialBox>
  );
}
