import Head from 'next/head';
import UAParser from 'ua-parser-js';

import { PutItemCommand } from '@aws-sdk/client-dynamodb';
import { ProductType } from '@types';
import { Session } from 'next-session/lib/types';
import { Seo, generateUrlWithTracking } from '@bluefinenterprises/bluefin-libs';
import {
  CMS_ENDPOINT,
  geolocate,
  preprocessProduct,
  getSession,
  dynamodb,
  C411_CLICKS_TABLE,
} from '@backend';
import { useBeacons } from '@components';
import * as process from 'process';
import ReCaptchaSection from './captcha/ReCaptchaSection';

interface RedirectProps {
  redirectUrl: string;
  costPerClick: number | null;
  conversionGoalGoogle: string | null;
  conversionGoalBing: string | null;
  expectedRevenuePerClick: number | null;
  productId: string,
  clientUrl: string
  // emblemUrl: string;
  // emblemAlt: string;
  // turnstileEnabled: boolean
}

export const Redirect = ({
  redirectUrl, costPerClick, conversionGoalGoogle, conversionGoalBing, expectedRevenuePerClick,
  clientUrl, productId,
}: RedirectProps) => {
  useBeacons({
    url: redirectUrl, costPerClick, conversionGoalGoogle, conversionGoalBing, expectedRevenuePerClick,
  });
  // eslint-disable-next-line consistent-return
  return (
    <>
      <Head>
        <title>Redirecting - Comparison411.com</title>
        <Seo
          title='Redirecting - Comparison411.com'
          description='Comparison411 redirect page, hang tight we are processing yor request, it may take little longer.'
          imageUrl='https:// d3l9pgwma9u3kz.cloudfront.net/hero-images/main-page.jpg'
          pageUrl='/redirect/'
        />
      </Head>

      <ReCaptchaSection destination={redirectUrl} source={clientUrl || 'https://comparison411.com'} productId={productId} />
    </>
  );
};

const fetchProduct = async (productId: string): Promise<ProductType | undefined> => {
  try {
    const rs = await fetch(`${CMS_ENDPOINT}/products/${productId}`);
    return await rs.json();
  } catch (e) {
    return undefined;
  }
};

const isMobile = (req): boolean => {
  const mobileDeviceTypes = ['mobile', 'tablet', 'wearable'];
  const userAgent = req.headers['user-agent'];
  const result = new UAParser(userAgent).getResult();
  return result.device && mobileDeviceTypes.includes(result.device.type);
};

const extractUrl = (product, mobile: boolean, source: string): string => {
  const networkPattern = product?.network?.placeholder;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  let { landingPageUrl } = product;
  if (mobile && product.mobileLandingPageUrl) {
    landingPageUrl = product.mobileLandingPageUrl;
  }

  return generateUrlWithTracking({
    clientRoute: source,
    placeholder: networkPattern,
    finalUrl: landingPageUrl,
  });
};

const extractCost = (product, mobile): number => {
  if (mobile) {
    return product.mobileCostPerClick || product.costPerClick || 0;
  }
  return product.costPerClick || 0;
};

const saveClick = async (session, product, mobile, query, req, destinationUrl, geo): Promise<void> => {
  const timestamp = Date.now();
  // eslint-disable-next-line no-bitwise
  const epochDays = ~~(timestamp / 86400000); // force integer division with bitwise not
  const cost = extractCost(product, mobile) || 0;
  const clickId = Buffer.from(`${session.id}:${product.id}`).toString('base64url');
  const categoryNames = product.categories.map((c) => c.name).join('|');
  await dynamodb.send(new PutItemCommand({
    TableName: C411_CLICKS_TABLE,
    Item: {
      clickId: { S: clickId },
      timestamp: { N: timestamp.toString() },
      redirectCount: { N: session.redirects.toString() },
      placementId: { S: product.id.toString() },
      sessionId: { S: session.id },
      epochDay: { N: epochDays ? epochDays.toString() : '0' },
      cost: { N: cost ? cost.toString() : '0' },
      referer: { S: req.headers.referer || '' },
      sourceUrl: { S: query.url || '' },
      destinationUrl: { S: destinationUrl },
      network: { S: product?.network?.name || '' },
      ipAddress: { S: req.headers['x-forwarded-for'] || '' },
      userAgent: { S: req.headers['user-agent'] },
      country: { S: geo?.countryIsoCode || '' },
      data: {
        S: JSON.stringify({
          productName: product.name,
          network: product.network,
          offlineConversionsEnabled: product?.offlineConversionsEnabled,
          categoryNames,
        }),

      },
    },
  }));
};

/**
 * Handles the server request for the redirect page.
 * @param req represents the request made by the client.
 * @param res
 * @param query
 * @param params
 */
export const getServerSidePropsRedirect = async ({
  req, res, query, params,
}) => {
  const session: Session = await getSession(req, res);
  session.redirects = session.redirects ? session.redirects + 1 : 1;
  const productId: string | undefined = query?.product || params?.productId;
  if (!productId) return { props: {} };
  const mobile: boolean = isMobile(req);
  const responses: [
    PromiseSettledResult<{ city?: undefined, countryIsoCode?: undefined } | { city: any, countryIsoCode: any }>,
    PromiseSettledResult<ProductType>,
  ] = await Promise.allSettled([geolocate(req), fetchProduct(productId)]);
  const [geoOriginal, originalProduct] = responses.map((v) => v.status === 'fulfilled' && v.value);
  if (!originalProduct) return { props: {} };
  const geo = geoOriginal as { city?: undefined, countryIsoCode?: undefined } | { city: any, countryIsoCode: any };
  const product: ProductType | undefined = preprocessProduct(originalProduct, geo?.countryIsoCode);
  if (!product) return { props: {} };
  const destinationUrl: string | null = extractUrl(product, mobile, query.url) || null;
  await saveClick(session, product, mobile, query, req, destinationUrl, geo); // Sometimes, requests are cancelled without await keyword
  const globalTurnstileEnabled = process.env.GLOBAL_TURNSTILE_ENABLED ? JSON.parse(process.env.GLOBAL_TURNSTILE_ENABLED) : true;
  const turnstileEnabled = globalTurnstileEnabled
    || !!product.turnstileEnabled
    || !!product.categories.find((cat) => cat.turnstileEnabled)
    || !!product.network?.turnstileEnabled;
  return {
    props: {
      redirectUrl: destinationUrl.replace('%3B', ';'),
      clientUrl: query.url || 'https://comparison411.com',
      productId: product.id,
      costPerClick: product.costPerClick,
      conversionGoalGoogle: product.conversionGoalGoogle,
      conversionGoalBing: product.conversionGoalBing,
      expectedRevenuePerClick: product.expectedRevenuePerClick,
      emblemUrl: product.emblem,
      emblemAlt: `${product.name} logo`,
      turnstileEnabled,
    },
  };
};
